
$().ready(function(){
    jQuery.validator.addMethod("telefon", function(phone_number, element) {
        phone_number = phone_number.replace(/\s+/g, "");
        return this.optional(element) || phone_number.length > 9 &&
        phone_number.match(/^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/);
    }, "Si us plau, especefiqueu un telèfon amb un format vàlid");

    $('form').validate({
        rules: {
            telef: {
                telefon: true
            }
        }
    });

    $(".telprefix input").intlTelInput({
        //initialCountry: "es",
        preferredCountries: ["es", "gb", "fr", "ru"],
        utilsScript: "/media/js/formularis/utils.js",
        nationalMode: false
    });
});
