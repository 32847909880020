var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
    'use strict';
    //validacio formulari
    $(this).validate({
        errorPlacement: function(error, element) {
            if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                if (element.closest('div').find('input').length > 1) {
                    error.insertAfter(element.closest('div').find('p.type-label'));
                } else {
                    error.insertAfter(element.parent('label'));
                }
            } else {
                error.insertAfter(element);
            }
        }
    });
});

//datapicker

$('.houdiniForm .hasDatepicker').fdatepicker({language: idioma});

//chosen
$('.houdiniForm .chzn-select').select2();
$('.multiple-select-help').hide(); //amaga misatge d'ajuda dels selects multiples

$.fn.raty.defaults.path = '/media/img';
$.fn.raty.defaults.cancel = false;

//valoracio
$('.houdiniForm .scoreField').each(function() {
    'use strict';
    var field = $(this);
    var id = '#' + field.attr('id');
    var score = $('<div></div>');
    score.raty({
        half: true,
        hints: ['', '', '', '', ''],
        score: function() {
            return field.val();
        },
        targetScore: id
    });
    field.hide();
    field.after(score);
});

//select de dnis
$('.houdiniForm .dninifnie').each(function() {
    'use strict';
    $(this).find('select').on('change', function() {
        var value = $(this).val();
        var input = $(this).closest('div.row').find('input');

        if (value === 'DNI') {
            input.rules('add', {
                dni: true,
                cifES: false,
                nieES: false
            });
        } else if (value === 'CIF') {
            input.rules('add', {
                dni: false,
                cifES: true,
                nieES: false
            });
        } else if (value === 'NIE') {
            input.rules('add', {
                dni: false,
                cifES: false,
                nieES: true
            });
        }
    });
});
